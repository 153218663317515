<template>
    <section id="urgence">
        <div class="container">
            <div class="title-effect position-relative">
                <h1 class="text-center title-text p-2">LES NUMEROS D'URGENCE</h1>
                <div class="background-text text-center">URGENCES</div>
            </div>
            <p class="text-center">Les numéros d'appel d'urgence permettent de joindre GRATUITEMENT les secours 24H/24</p>
        </div>
        
        <div class="container">
            <div class="row">
                <div class="col-md-4 my-2" v-for="urgence in urgences" :key="urgence.id">
                     <div class="flip-card">
                        <div class="flip-card-inner">
                            <div class="flip-card-front d-flex justify-content-center justify-items-center">
                             <div class="d-flex justify-content-between align-items-center p-4">
                                <div>
                                    <img class="profil-img" v-if="urgence.image" :src="require(`../../assets/images/import/cpts/${urgence.image}`)" alt="">
                                </div>
                                <div>
                                    <h1 class="text-right">{{urgence.number}}</h1>
                                    <h4 class="text-right">{{urgence.title}}</h4>
                                    <p class="text-muted text-right">{{urgence.abstract}}</p>
                                </div>
                            </div>
                            </div>
                            <div class="flip-card-back d-flex justify-content-between align-items-center p-4">
                                <div class="">
                                    <p>{{urgence.description}}</p>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <!-- liste des lieux -->
        <div class="container mt-5">
            <div class="card center-list">
                <div class="card-header text-center">
                    <h3>Nom du centre, Nom du lieu ...</h3>
                    <p class="text-muted">Territoire</p>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class="text-left">
                            <h4> NOM DU CENTRE </h4>
                            <p>
                                Adresse : ** *** ********** <br>
                                Ville : ********* <br>
                                Horaire : Du Lundi au Vendrei : *h - **h <br>
                                                Le Samedi : **h - **h  <br>
                                                Le Dimanche : **h - **h <br>
                            </p>
                        </div>
                        <div class="text-right">
                            <h4> NOM DU CENTRE </h4>
                            <p>
                                Adresse : ** *** ********** <br>
                                Ville : ********* <br>
                                Horaire : Du Lundi au Vendrei : *h - **h <br>
                                                Le Samedi : **h - **h  <br>
                                                Le Dimanche : **h - **h <br>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card center-list my-5">
                <div class="card-header text-center">
                    <h3>Nom du centre, Nom du lieu ...</h3>
                    <p class="text-muted">Territoire</p>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class="text-left">
                            <h4> NOM DU CENTRE </h4>
                            <p>
                                Adresse : ** *** ********** <br>
                                Ville : ********* <br>
                                Horaire : Du Lundi au Vendrei : *h - **h <br>
                                                Le Samedi : **h - **h  <br>
                                                Le Dimanche : **h - **h <br>
                            </p>
                        </div>
                        <div class="text-right">
                            <h4> NOM DU CENTRE </h4>
                            <p>
                                Adresse : ** *** ********** <br>
                                Ville : ********* <br>
                                Horaire : Du Lundi au Vendrei : *h - **h <br>
                                                Le Samedi : **h - **h  <br>
                                                Le Dimanche : **h - **h <br>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name:"urgence",
    data(){
        return{
            urgences:[
                { number:"15", title:"Samu", image:"samu.png", description:"Pour obtenir l'intervention d'une équipe médicale lors d'une situation de détresse vitale, ainsi que pour être redirigé vers un organisme de permanence de soins. ", abstract:"Le service d'aide médical Urgent"},
                { number:"18", title:"SAPEURS-POMPIERS",image:"pompier.png", description:"Pour signaler une situation de péril ou un accident concernant des biens ou des personnes et obtenir leur intervention rapide.", abstract:""},
                { number:"17", title:"POLICE-SECOURS", image:"police.png", description:"Pour signaler une infraction qui nécessite l'intervention immédiate de la police.", abstract:""},
                { number:"112", image:"europe.png", title:"NUMERO D'APPELD'URGENCE EUROPEEN", description:"Si vous êtes victime ou témoin d'un accident dans un pays de l'Union Européenne", abstract:""},
                { number:"3114", image:"suicide.png", title:"NUMERO NATIONAL PREVENTION DU SUICIDE", description:"C'est le moment d'appeler ! Ecoute professionnelle et confidentielle 24h/24 - 7j/7Appel gratuit", abstract:""},
                { number:"3237", image:"pharmacie.png", title:"PHARMACIE DE GARDE", description:"Pour connaître la Pharmacie de garde la plus proche Composez le 32 37  accessible 24H/24", abstract:""},
            ]
        }
    },
    computed:{
        // getImages(pic){
        //     return require('../assets/'+pic)
        // }
    }
}
</script>
<style scoped>
.center-list .card-header {
    background-color: #8C1078;
    color: white;
}
.card {
    box-shadow: 0px 4px 9px rgb(144, 144, 144);
}
.profil-img {
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
}


 /* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
    box-shadow: 0px 4px 9px rgb(144, 144, 144);
  background-color: transparent;
  width: 100%;
  height: 200px;
  border: 1px solid #f1f1f1;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: rgb(255, 255, 255);
  color: black;
}

/* Style the back side */
.flip-card-back {
    color: white;
  transform: rotateY(180deg);
} 
.flip-card-back:nth-child(n+1){
background-color: #5ebf56;
}
.flip-card-back:nth-child(2n){
background: #1bbccd;
}
</style>